import React, { useState, createRef, useMemo, useEffect } from 'react';
import Slider from 'react-slick';
import { debounce as _debounce } from 'lodash';
import Section from '@latitude/section';
import Button from '@/components/lab-components/Button';
import {
  TEXT_TYPE,
  ICON_VARIANT,
  BUTTON_THEME,
  BREAKPOINT,
  PROMO_CARD_TAG_THEME,
} from '@/utils/constants';
import { pxAsNumber } from '@/utils/helpers';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import styled from 'styled-components';
import PromoCardTag from '../PromoCardTag';
import Modal from '@/components/Modal/Modal';
import SvgInline from '@latitude/svg-inline';
import nextArrow from '../../../images/slick-next.png';
import prevArrow from '../../../images/slick-prev.svg';
import './_promo_banner.scss';

const StyledSvg = styled(SvgInline)`
  width: 18.058px;
  height: 18px;
`;

const Promo = ({
  title,
  promoCards,
  backgroundColor,
  headingSize = 'Heading 3',
  alignment,
}) => {
  const TypographyTitle = TEXT_TYPE['Heading 3'];

  const OPTIONS_TITLE = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <TypographyTitle className="promo-banner-title">
          {children}
        </TypographyTitle>
      ),
    },
  };

  const sliderSettings = {
    dots: false,
    speed: 500,
    arrows: true,
    slidesToShow: 6,
    infinite: false,
    variableWidth: true,
    nextArrow: <img src={nextArrow} alt="slick-next" width={55} height={55}/>,
    prevArrow: <img src={prevArrow} alt="slick-prev" width={55} height={55}/>,
    responsive: [
      {
        breakpoint: 840,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 1200,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 1960,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 2730,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 3520,
        settings: { slidesToShow: 5 },
      }
    ],
  };

  const cardsTitleRef = useMemo(() => {
    const refs = {};
    promoCards?.forEach((card, index) => {
      refs[card.id] = createRef();
    });
    return refs;
  }, [promoCards]);

  const setTitleHeight = (height) => {
    Object.keys(cardsTitleRef).forEach((key) => {
      const ref = cardsTitleRef[key];
      if (ref && ref.current) {
        ref.current.style.minHeight = height ? `${height}px` : "auto";
      }
    });
  }

  const equalizeHeadingHeight = () => {
    let max = 0;
    Object.keys(cardsTitleRef).forEach((key) => {
      const element = cardsTitleRef[key]?.current;
      if (element && element.offsetHeight > max) {
        max = element.offsetHeight;
      }
    });
    setTitleHeight(max);
  };

  const cardsBodyRef = useMemo(() => {
    const refs = {};
    promoCards?.forEach((card, index) => {
      refs[card.id] = createRef();
    });
    return refs;
  }, [promoCards]);

  const setBodyHeight = (height) => {
    Object.keys(cardsBodyRef).forEach((key) => {
      const ref = cardsBodyRef[key];
      if (ref && ref.current) {
        ref.current.style.minHeight = height ? `${height}px` : "auto";
      }
    });
  }

  const equalizeBodyHeight = () => {
    let max = 0;
    Object.keys(cardsBodyRef).forEach((key) => {
      const element = cardsBodyRef[key]?.current;
      if (element && element.offsetHeight > max) {
        max = element.offsetHeight;
      }
    });
    setBodyHeight(max);
  };

  const cardsRef = useMemo(() => {
    const refs = {};
    promoCards?.forEach((card, index) => {
      refs[card.id] = createRef();
    });
    return refs;
  }, [promoCards]);

  const setCardHeight = (height) => {
    Object.keys(cardsRef).forEach((key) => {
      const ref = cardsRef[key];
      if (ref && ref.current) {
        ref.current.style.minHeight = height ? `${height - 50}px` : "auto";
      }
    });
  }

  const equalizeCardHeight = () => {
    let max = 0;
    Object.keys(cardsRef).forEach((key) => {
      const element = cardsRef[key]?.current;
      if (element && element.offsetHeight > max) {
        max = element.offsetHeight;
      }
    });
    setCardHeight(max);
  };

  const handleWindowResize = () => {
    setCardHeight();
    equalizeCardHeight();
    setTitleHeight();
    equalizeHeadingHeight();
    setBodyHeight();
    equalizeBodyHeight();
  };

  const debouncedHandleResize = _debounce(handleWindowResize, 100);

  useEffect(() => {
    equalizeCardHeight();
    equalizeHeadingHeight();
    equalizeBodyHeight();
    window.addEventListener('resize', debouncedHandleResize);
    return () => window.removeEventListener('resize', debouncedHandleResize);
  }, [promoCards, cardsTitleRef, cardsRef, cardsBodyRef]);

  useEffect(() => {
    debouncedHandleResize();
  }, []);

  return (
    <div className="promo-banner">
      <Section
        className={`promo-section ${
          promoCards?.length > 1 && 'slider'
        } common_tag_style promo-bg-${backgroundColor?.toLowerCase()} promo-bg-${backgroundColor?.toLowerCase()}${promoCards?.length > 1 && '--slider'}`}
      >
        <div className={`${promoCards?.length === 2 && 'text-center'}`}>
          {title && documentToReactComponents(title, OPTIONS_TITLE)}
        </div>
        {promoCards?.length === 1 ? (
          <div className="row">
            {promoCards?.map((promoCard) => (
              <div
                key={promoCard?.id}
                className={`col-12 col-sm-12 col-md-12 promo-card-span-12`}
              >
                <PromoCardContent
                  cardsTitleRef={cardsTitleRef}
                  cardsBodyRef={cardsBodyRef}
                  promoCard={promoCard}
                  alignment={alignment}
                  headingSize={headingSize}
                  backgroundColor={backgroundColor}
                />
              </div>
            ))}
          </div>
        ) : (
          <Slider
          {...sliderSettings}
          css={
            promoCards?.length === 2 &&
            `
              && .slick-track{
                justify-content: center;
                margin: 0 auto !important
              }
            `
          }
        >
            {promoCards?.map((promoCard) => (
              <div ref={cardsRef[promoCard.id]} key={promoCard?.id} className="promo-card">
                <PromoCardContent
                  cardsTitleRef={cardsTitleRef}
                  cardsBodyRef={cardsBodyRef}
                  promoCard={promoCard}
                  alignment={alignment}
                  headingSize={headingSize}
                  backgroundColor={backgroundColor}
                />
              </div>
            ))}
          </Slider>
        )}
      </Section>
    </div>
  );
};

const DescriptionRenderer = ({
  descriptionDocument,
  disclaimerText,
  handleModalClick,
  showDisclaimerInModal,
}) => {
  const options = {
    renderNode: {
      [MARKS.SUPERSCRIPT]: (node, children) => <sup>{children}</sup>,
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return (
          <>
            {children}
            {disclaimerText &&
              !children?.includes('') &&
              !children?.includes('\n') &&
              showDisclaimerInModal && (
                <span onClick={handleModalClick}>
                  <StyledSvg
                    name="info-block-outline"
                    css={`
                      && path {
                        fill: #0061ee;
                      }
                    `}
                  />
                </span>
              )}
          </>
        );
      },
    },
  };
  return <>{documentToReactComponents(descriptionDocument, options)}</>;
};

const PromoCardContent = ({
  cardsTitleRef,
  cardsBodyRef,
  promoCard,
  alignment,
  headingSize,
  backgroundColor
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const Typography = TEXT_TYPE[headingSize];
  const OPTIONS = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography>{children}</Typography>
      ),
    },
  };

  const handleModalClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getButtonType = (theme, darkTheme) => {
    if (backgroundColor === 'Dark') {
      if (theme === 'Outline') return BUTTON_THEME.OUTLINE;
      if (darkTheme === 'White') return BUTTON_THEME.DEFAULTINVERSE;
    } else {
      if (theme === 'Blue') return BUTTON_THEME.DEFAULT;
      if (theme === 'Outline') return BUTTON_THEME.OUTLINE;
    }
    return BUTTON_THEME.APPLICATION;
  };

  const getTagType = (tagName) => {
    if (tagName === 'Personal Loans')
      return PROMO_CARD_TAG_THEME.PERSONAL_LOANS;
    else if (tagName === 'Credit Cards')
      return PROMO_CARD_TAG_THEME.CREDIT_CARDS;
  };

  function createPanelName(name) {
    if (typeof name === 'string') {
      const s0 = name.trim().toLowerCase();
      const s1 = s0.replace(/\s+/g, '_');
      const s2 = s1.replace(/[^a-zA-Z0-9_-]/g, '');
      return s2;
    }
  }

  return (
    <>
      <div className={`promo-card-img order-${alignment?.toLowerCase()}`}>
        <img
          src={promoCard?.image?.file?.url}
          alt={promoCard?.image?.file?.fileName}
        />
        {promoCard?.discountTag && 
          <div className='promo-card-discount'>
            {documentToReactComponents(promoCard?.discountTag)}
          </div>
        }
        {promoCard?.logoTag && 
          <div className='promo-card-logo'>
            <img
               src={promoCard?.logoTag?.file?.url}
               alt={promoCard?.logoTag?.file?.fileName}
            /> 
          </div>
        }
      </div>
      <div className='promo-card-content'>
        {promoCard?.tag && (
          <PromoCardTag
            tagName={promoCard?.tag}
            theme={getTagType(promoCard?.tag)}
          />
        )}
        <div ref={cardsTitleRef[promoCard.id]}>
            {promoCard?.headingCopy &&
            documentToReactComponents(promoCard?.headingCopy, OPTIONS)}
        </div>
        <div ref={cardsBodyRef[promoCard.id]} className="promo-card-body">
          {promoCard?.description && (
            <DescriptionRenderer
              descriptionDocument={promoCard?.description}
              disclaimerText={promoCard?.disclaimer}
              handleModalClick={handleModalClick}
              showDisclaimerInModal={promoCard?.showDisclaimerInModal}
            />
          )}
        </div>
        {promoCard?.primaryButton?.url && (
          <div className="promo-actions-wrapper">
            <Button
              theme={getButtonType(
                promoCard?.primaryButtonTheme,
                promoCard?.primaryButtonDarkTheme
              )}
              href={promoCard?.primaryButton?.url}
              target="_blank"
              trackId="get-started"
              trackEventData={{
                category: 'cta',
                action: 'quote-link',
                location: createPanelName(`${promoCard?.name}primarybtn`),
                label: promoCard?.primaryButton?.text
              }}
              trackProductId={['PLAULF-WEB']}
              text={promoCard?.primaryButton?.text}
            />
            {promoCard?.linkButton?.url && (
              <Button
                theme={
                  backgroundColor === 'Dark'
                    ? BUTTON_THEME.LINKINVERSE
                    : BUTTON_THEME.LINK
                }
                href={promoCard?.linkButton?.url}
                target="_blank"
                trackId="get-started"
                trackEventData={{
                  category: 'cta',
                  action: 'quote-link',
                  location: createPanelName(`${promoCard?.name}linkbtn`),
                  label: promoCard?.linkButton?.text
                }}
                trackProductId={['PLAULF-WEB']}
                text={promoCard?.linkButton?.text}
                icon={ICON_VARIANT.CHEVRON_RIGHT}
              />
            )}
          </div>
        )}
        {promoCard?.disclaimer && promoCard?.showDisclaimerInModal ? (
        <Modal
          id={`promo-card-modal-${promoCard?.id}`}
          isOpen={isModalOpen}
          onRequestClose={handleModalClick}
          title={documentToReactComponents(promoCard?.disclaimerTitle)}
          content={documentToReactComponents(promoCard?.disclaimer)}
        />
      ) : (
        promoCard?.disclaimer &&
        documentToReactComponents(promoCard?.disclaimer)
      )} 
      </div>
    </>
  );
};

export default Promo;